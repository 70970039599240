<template>
  <div class="wrapper">
    <!-- pc导航 -->

    <div>
      <div class="IndexBanner" style="height: 976px;">
        <div class="swiper-container swiper-container-horizontal">
          <div class="swiper-wrapper" style="transform: translate3d(-1920px, 0px, 0px); transition-duration: 0ms;">
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active swiper-slide-prev"
              data-swiper-slide-index="0" style="height: 976px; width: 1920px;">

              <img src="./assets/tiyaJob/7b904742-4afd-4839-8f3f-9401868c3313.png" style="height: 976px;">
            </div>
            <div class="swiper-slide swiper-slide-active swiper-slide-duplicate-next swiper-slide-duplicate-prev"
              data-swiper-slide-index="0" style="height: 976px; width: 1920px;">

              <img src="./assets/tiyaJob/7b904742-4afd-4839-8f3f-9401868c3313.png" style="height: 976px;">
            </div>
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active swiper-slide-next"
              data-swiper-slide-index="0" style="height: 976px; width: 1920px;">

              <img src="./assets/tiyaJob/7b904742-4afd-4839-8f3f-9401868c3313.png" style="height: 976px;">
            </div>
          </div>
          <div class="IndexBanner_Layout">
            <h2 data-scroll-reveal="wait 0.1s, then enter left and move 40px over 1s" data-scroll-reveal-id="1"
              style="-webkit-transform: translatex(-40px);transform: translatex(-40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">Let us connect through voice</h2>
          </div>
        </div>
        <a href="javascript:void 0" class="ArrowImg"></a>
      </div>
      <!-- IndexBanner end -->
      <div id="page"></div>
      <div class="Teams Base_Size">
        <div class="container">
          <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s"
            data-scroll-reveal-id="2"
            style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
            data-scroll-reveal-initialized="true">TEAMS</h2>
          <div class="clearfix">
            <a href="javascript:void 0" target="_blank" class="Corporate_Fx"
              data-scroll-reveal="wait 0.12s, then enter bottom and move 40px over 1s" data-scroll-reveal-id="3"
              style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="Corporate_Icon">
                <img src="./assets/tiyaJob/0bc66939-32d7-41d8-8d65-55bf846e0c76.png">
              </div>
              <h2>Business<br>Operations</h2>
              <p>Focusing on the management of complex business and challenges, the Business Operations...
                Team in TIYA provides the best data-driven to improve the In-App operations process. It
                also refers to activities that businesses engage in on a daily basis to increase the
                value of the company and earn a profit.</p>
              <div class="More"></div>
            </a><a href="javascript:void 0" target="_blank" class="Corporate_Fx"
              data-scroll-reveal="wait 0.14s, then enter bottom and move 40px over 1s" data-scroll-reveal-id="4"
              style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="Corporate_Icon">
                <img src="./assets/tiyaJob/f33d5640-c440-496a-922a-25f0ee840f4d.png">
              </div>
              <h2>Strategy<br>Development</h2>
              <p>A corporate Strategy Development Department surveys those responsible for company
                operations to... gather information on challenges and objectives. It consolidates
                individual strategic aims into an overall approach and invites feedback from the
                departments concerned. Meanwhile, the employee in this department need to coordinate the
                different initiatives that the strategy requires.</p>
              <div class="More"></div>
            </a><a href="javascript:void 0" target="_blank" class="Corporate_Fx"
              data-scroll-reveal="1" data-scroll-reveal-id="5"
              style="-webkit-transform: translatey(50px);transform: translatey(50px);opacity: 0;-webkit-transition: -webkit-transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;transition: transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="Corporate_Icon">
                <img src="./assets/tiyaJob/24a0eea2-c547-4fe8-afb6-16af6ec4b9b1.png">
              </div>
              <h2>Big Data</h2>
              <p>Deriving meaningful insights from the data, and converting knowledge into action are the
                main responsibilities of Big Data Department. In TIYA, the Big Data team needs to
                develop analytic solutions on big volumes of data to uncover insights and improve
                performance. In other words, data (and the intelligence that comes from that data) must
                become the central focus of the business.</p>
              <div class="More"></div>
            </a><a href="javascript:void 0" target="_blank" class="Corporate_Fx"
              data-scroll-reveal="1" data-scroll-reveal-id="6"
              style="-webkit-transform: translatey(50px);transform: translatey(50px);opacity: 0;-webkit-transition: -webkit-transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;transition: transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="Corporate_Icon">
                <img src="./assets/tiyaJob/4dbba437-bd35-493e-a2cf-058c1792156d.png">
              </div>
              <h2>Design</h2>
              <p>In one project, Design Team is typically brought on at the beginning of the project and
                continue to work on throughout its life-cycle. TIYA’s design team is primarily
                responsible for taking creative ideas and bringing them to life using their professional
                skills.</p>
              <div class="More"></div>
            </a><a href="javascript:void 0" target="_blank" class="Corporate_Fx"
              data-scroll-reveal="1" data-scroll-reveal-id="7"
              style="-webkit-transform: translatey(50px);transform: translatey(50px);opacity: 0;-webkit-transition: -webkit-transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;transition: transform 0.66s ease-in-out 0s,  opacity 0.66s ease-in-out 0s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="Corporate_Icon">
                <img src="./assets/tiyaJob/7be99ec9-56ba-4b08-9bc9-3e1e665731e2.png">
              </div>
              <h2>Support<br>Department</h2>
              <p>The support departments such as Human Resources and Finance department develop and
                maintain many internal systems necessary for perfect communication and work. These teams
                continually create plans to improve the overall efficiency and productivity of the TIYA
                business.</p>
              <div class="More"></div>
            </a>
          </div>
        </div>
      </div>
      <div class="Locations">
        <div class="container">
          <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s"
            data-scroll-reveal-id="8"
            style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
            data-scroll-reveal-initialized="true">
            LOCATIONS</h2>
          <div class="Earth">
            <img src="./assets/tiyaJob/earth.png" width="100%" class="Earth_Img">
            <div class="Earth_Ct">
              <div class="Earth_Dian"></div>
              <div class="Earth_Line">
                <img src="./assets/tiyaJob/line.png" width="100%" class="">
                <div class="Earth_Bordert Base_Border"></div>
              </div>
              <div class="Earth_Line1">
                <img src="./assets/tiyaJob/line1.png" width="100%" class="">
                <div class="Earth_Border1 Base_Border"></div>
              </div>
              <div class="Earth_Line2">
                <img src="./assets/tiyaJob/line2.png" width="100%" class="">
                <div class="Earth_Border2 Base_Border"></div>
              </div>
              <div class="Earth_Line3">
                <img src="./assets/tiyaJob/line3.png" width="100%" class="">
                <div class="Earth_Border3 Base_Border"></div>
              </div>
              <div class="Earth_Line4">
                <img src="./assets/tiyaJob/line4.png" width="100%" class="">
                <div class="Earth_Border4 Base_Border"></div>
              </div>
              <div class="Earth_Line5">
                <img src="./assets/tiyaJob/line5.png" width="100%" class="">
                <div class="Earth_Border5 Base_Border"></div>
              </div>
              <div class="Earth_Line6">
                <img src="./assets/tiyaJob/line6.png" width="100%" class="">
                <div class="Earth_Border6 Base_Border"></div>
              </div>
              <div class="Earth_Line7">
                <img src="./assets/tiyaJob/line7.png" width="100%" class="">
                <div class="Earth_Border7 Base_Border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Why_Tiya Base_Size">
        <div class="container">
          <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s"
            data-scroll-reveal-id="9"
            style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
            data-scroll-reveal-initialized="true">WHY TIYA
          </h2>
          <div class="clearfix">
            <a href="javascript:void(0)" class="Technology_Fx">
              <img data-scroll-reveal="wait 0.12s, then enter right and move 40px over 1s"
                src="./assets/tiyaJob/c355a64d-33ed-4ecd-987d-8702c28c5e23.png" data-scroll-reveal-id="10"
                style="-webkit-transform: translatex(40px);transform: translatex(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
              <div class="Globalization_More" data-scroll-reveal="wait 0.14s, then enter left and move 40px over 1s"
                data-scroll-reveal-id="11"
                style="-webkit-transform: translatex(-40px);transform: translatex(-40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
                <div class="CenterPor">
                  <div class="Globalization_Scroll">
                    <h2>TIYA is a learning<br>organization by nature</h2>
                    <p>Chance for you to build a brand new culture together with the team. We
                      designed our own leadership program and our learning &amp; development
                      programs
                      are always evolving.</p>
                  </div>
                </div>
              </div>
            </a><a href="javascript:void(0)" class="Technology_Fx">
              <img data-scroll-reveal="wait 0.12s, then enter left and move 40px over 1s"
                src="./assets/tiyaJob/c465f465-3d45-4e42-87cc-14e5564dc927.png" data-scroll-reveal-id="12"
                style="-webkit-transform: translatex(-40px);transform: translatex(-40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.12s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
              <div class="Globalization_More" data-scroll-reveal="wait 0.14s, then enter right and move 40px over 1s"
                data-scroll-reveal-id="13"
                style="-webkit-transform: translatex(0);transform: translatex(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.14s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0.14s, opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
                <div class="CenterPor">
                  <div class="Globalization_Scroll">
                    <h2>We hire people with<br>growth mindset</h2>
                    <p>Infinite possibility growth opportunities for the company and personal career
                      evelopment.</p>
                  </div>
                </div>
              </div>
            </a><a href="javascript:void(0)" class="Technology_Fx">
              <img data-scroll-reveal="wait 0.12s, then enter right and move 40px over 1s"
                src="./assets/tiyaJob/7bc78948-ef80-4ac2-a851-69e84153c8f5.png" data-scroll-reveal-id="14"
                style="-webkit-transform: translatex(0);transform: translatex(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.12s,  opacity 1s ease-in-out 0.12s;transition: transform 1s ease-in-out 0.12s, opacity 1s ease-in-out 0.12s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
              <div class="Globalization_More" data-scroll-reveal="wait 0.14s, then enter left and move 40px over 1s"
                data-scroll-reveal-id="15"
                style="-webkit-transform: translatex(0);transform: translatex(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.14s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0.14s, opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
                <div class="CenterPor">
                  <div class="Globalization_Scroll">
                    <h2>A multinational<br>company</h2>
                    <p>Diversified culture leads to new insights and improves your languages, offers
                      you opportunity to work abroad and expand your horizons.</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer end -->
    <div class="go_top"></div>
  </div>
</template>

<style>
@import './assets/tiyaJob/reset.css';
@import './assets/tiyaJob/pc.css';
@import './assets/tiyaJob/phone.css';
@import './assets/tiyaJob/apple.css';
@import './assets/tiyaJob/swiper-3.4.2.min.css';
</style>

<script>
export default {
  mounted() {
    var timeControl1, timeControl2, lock;
    var $ = window.$

      ; (function (doc, win) {
        var docEl = doc.documentElement,
          resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
          recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = '100px';
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
            }
          };

        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener('DOMContentLoaded', recalc, false);
      })(document, window);

    //返回顶部
    // $(".go_top").click(function() {
    // 	$("html,body").animate({
    // 		scrollTop: '0px'
    // 	});
    // });
    $(function () {
      $(".Search_KeyWord").bind('keypress', function (event) {
        if (event.keyCode === 13) {
          window.location = '/job/social/index.html?key=' + $(this).val().trim() + '#page'
        }
      });
      $(".ArrowImg").click(function () {
        $("html, body").animate({
          scrollTop: $("#page").offset().top
        }, {
          duration: 500,
          easing: "swing"
        });
        return false;
      });
      $(".Search_Icon").click(function () {
        $(".b_head").addClass('on');
        $("body").css('overflow', 'hidden');
        setTimeout(function () {
          $(".MaskShow").fadeIn();
          $(".Search_Center").addClass('on');
        }, 200)
      })
      $(".Search_Job .CloseImg").click(function () {
        $(".b_head").removeClass('on');
        $("body").css('overflow', 'initial');
        setTimeout(function () {
          $(".MaskShow").fadeOut();
          $(".Search_Center").removeClass('on');
        })
      });
      if (window.navigator.userAgent.toLowerCase().indexOf('mac') > -1) {
        $("head link[rel='stylesheet']").last().after(
          "<link rel='stylesheet' href='css/apple.css' type='text/css'>");
      }
    });
    handleJs1();
    window.resize = function () {
      handleJs1();
    }

    function handleJs1() {
      if (window.innerWidth >= 1023) {
        var Height = document.documentElement.clientHeight;
        $(".OtherBanner,.OtherBanner img").height(Height);
      }
    }

    $(function () {
      handleJs1();
      if (window.innerWidth < 1023) {
        $(".menu-li ul li > a").click(function () {
          if ($(this).parent("li").hasClass("on")) {
            $(".menu-li ul li dl").stop().slideUp();
            $(".menu-li ul li").removeClass("on");
          } else {
            $(".menu-li ul li dl").stop().slideUp();
            $(this).parent("li").find("dl").stop().slideDown();
            $(".menu-li ul li").removeClass("on");
            $(this).parent("li").addClass("on");
          }
        });
        $('.menu-li dl dt .MenuMain').on('click', function () {
          $(this).toggleClass("Nor");
        });
        // 测导航
        $('.mobile-nav').on('click', function () {
          if (!$(this).hasClass("on")) {
            $(this).addClass("on");
            $(".menu-li").slideDown();
          } else {
            $(this).removeClass("on");
            $(".menu-li").slideUp();
          }
        });
        $(".am_subfooterdiv h2").click(function () {
          if ($(this).parent(".am_subfooterdiv").hasClass("on")) {
            $(".am_subtag").stop().hide();
            $(".am_subfooterdiv").removeClass("on");
          } else {
            $(".am_subtag").stop().hide();
            $(this).parent(".am_subfooterdiv").find(".am_subtag").stop().show();
            $(".am_subfooterdiv").removeClass("on");
            $(this).parent(".am_subfooterdiv").addClass("on");
          }
        });

      } else {
        $(window).scroll(function () {
          if ($(window).scrollTop() >= 50) {
            $(".HeaderFlix").addClass('on');
          } else {
            $(".HeaderFlix").removeClass('on');
          }
        });
      }
      $(".Talents_Ct .Talents_LayoutR .Talents_Fx").hover(function () {
        $(this).addClass('active').siblings().removeClass('active');
        var index = $(this).index();
        $(".Talents_Ct .Talents_Img").eq(index).addClass('active').siblings().removeClass('active');
      });
      $(".Development_Ct .Talents_LayoutR .Talents_Fx").hover(function () {
        $(this).addClass('active').siblings().removeClass('active');
        var index = $(this).index();
        $(".Development_Ct .Talents_Img").eq(index).addClass('active').siblings().removeClass('active');
      });
      window.scrollReveal = new window.scrollReveal({
        reset: true,
        move: '50px'
      });
    })


    $(function () {
      $(window).scroll(function () {
        var earthTOp = $(".Earth").offset().top;
        // var more = $(".Globalization_More1").offset().top
        var scrollTop = $(this).scrollTop();

        // if (scrollTop >= earthTOp - 220) {
        // 	$(".Globalization_More1").addClass('active');
        // 	setTimeout(function() {
        // 		$(".Globalization_More2").addClass('active');
        // 	}, 2000)
        // } else {
        // 	$(".Globalization_More").removeClass('active');
        // }

        if (scrollTop >= earthTOp + 400 || scrollTop < earthTOp - 1300) {
          if (!lock) {
            return;
          }
          lock = false
          $(".Earth_Dian").removeClass("active");
          $(".Earth_Ct img").removeClass("active");
          $(".Base_Border").removeClass("rippletshow");
          $(".Earth_Border2,.Earth_Border3,.Earth_Border4,.Earth_Border5,.Earth_Border6,.Earth_Border7")
            .removeClass("active");
          $(".Earth_Bordert,.Earth_Border1").removeClass("active");
          timeControl1 && clearTimeout(timeControl1)
          timeControl2 && clearTimeout(timeControl2)
        } else if (scrollTop >= earthTOp - 500) {
          if (lock) {
            return
          }
          lock = true
          timeControl1 && clearTimeout(timeControl1)
          timeControl2 && clearTimeout(timeControl2)
          $(".Earth_Dian").addClass("active");
          $(".Earth_Ct img").addClass("active");
          $(".Base_Border").addClass("rippletshow");
          timeControl1 = setTimeout(function () {
            $(".Earth_Border2,.Earth_Border3,.Earth_Border4,.Earth_Border5,.Earth_Border6,.Earth_Border7")
              .addClass("active");
            timeControl2 = setTimeout(function () {
              $(".Earth_Bordert,.Earth_Border1").addClass("active");
            }, 680)
          }, 3200)
        }
      })
    })

    // banner轮播
    handleJs();

    function handleJs() {
      if (window.innerWidth >= 1023) {

        var Height = document.documentElement.clientHeight;
        $(".IndexBanner .swiper-slide img,.IndexBanner,.IndexBanner .swiper-slide").height(Height);
      }
      new window.Swiper('.IndexBanner .swiper-container', {
        autoplay: false,
        speed: 1000,
        loop: true,
        observer: true,
        observeParents: true,
        pagination: '.swiper-pagination',
        paginationClickable: true
      });
    }
  }
}
</script>